.customDropZone {
  li {
    background: none;
    .fw-bold {
      cursor: pointer;
    }
  }
}
.extraTest {
  margin-left: 5px;
}

.customDropZone {
  h5 {
    font-size: 16px;
  }
  @media only screen and (max-width: 1232px) {
    h5 {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 991px) {
    margin-top: 15px;
  }
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 2px dashed #363333;
  background-color: #fafafa;
  color: #8a5656;
}

.dropZoneText {
  cursor: pointer;
}
