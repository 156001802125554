.company_profile_container {
  margin-bottom: -15px;
  input[type="checkbox"] {
    height: 16px;
    width: 16px;
    border-radius: 4px;
    border: solid 1px #979797;
    background-color: #ffffff;
    margin-right: 5px;
  }
  input {
    padding: 0.5rem;
  }
}

.form_group {
  margin-bottom: 38px;
  select,
  input {
    height: 35px;
    color: black;
    padding-left: 5px;
    box-shadow: 0.5px 1px 4px grey;
    border: white;
    width: 100%;
    border-radius: 4px;
  }
  input[type="checkbox"] {
    box-shadow: none;
  }
  label {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // font-size: 11px;
    color: #333;
    margin-bottom: 0;
    &.mandatory::after {
      content: "*";
      padding-left: 2px;
    }
  }
  p.errMsg {
    color: red;
    font-size: 11px;
  }
}

.errMsg {
  color: red;
}

#peo-select:invalid {
  color: rgb(117, 117, 117);
}

#carrier-select-container {
  button {
    height: 35px;
    color: black;
    padding-left: 5px;
    box-shadow: 0.5px 1px 4px grey;
    width: 100%;

    span:first-child {
      font-size: 16px;
    }
  }
  input {
    padding-left: 0;
    box-shadow: none;
  }
}

#sameAsPrimary-1 {
  box-shadow: none;
  border: 2px solid black;
  width: 14px;
  margin-right: 5px;
}

.companyrow_margin {
  margin-top: -15px;
}

.feinrow_margin {
  margin-top: -20px;
}

.Phone_margin {
  margin-top: -15px;
}

.primary_margin {
  margin-top: -13px;
}

.street_margin {
  margin-top: -5px;
}

.mailing_margin {
  margin-top: -12px;
}

.mail_street_margin {
  margin-top: -5px;
}

.phone_number,
.years_in_business {
  width: 19%;
  padding-inline: 15px;
}

.entity_type {
  width: 40%;
  padding-inline: 15px;
}

.expected_start_date {
  width: 22%;
  padding-inline: 15px;
}

.expected_expiry_date {
  width: 22%;
  padding-inline: 15px;
}

.primary_margin_formGroup,
.mailing_margin_formGroup {
  margin-bottom: 19.95px;
}

.street1_margin,
.street2_margin {
  width: 51%;
  padding-inline: 15px;
}

.zip_1,
.state_1,
.zip_2,
.state_2 {
  width: 11%;
  padding-inline: 15px;
}

.city_1,
.city_2 {
  width: 27%;
  padding-inline: 15px;
}

.mailing_address_heading {
  padding-inline: 15px;
}

@media screen and (max-width: 992px) {
  .row {
    display: flex;
    flex-direction: column;
  }
  #phone-number,
  #entity-type,
  #years-in-business,
  #expected-start-date,
  #expected-expiry-date,
  #street-1,
  #zip-1,
  #city-1,
  #state-1,
  #street-2,
  #zip-2,
  #city-2,
  #state-2 {
    width: 100%;
  }
}
