// UW dashbaord.
.dashboard_container,
.submission_uw_dashboard {
  .MuiPaper-root {
    box-shadow: 1px 1px 12px 0px;
  }
  padding-left: 5px;
  padding-right: 5px;
}

.submission_uw_dashboard {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.dashboard_container_header {
  border-bottom-style: groove;
}

.materialTable_container {
  position: relative;
  
  p[class$="-displayedRows"],
  p[class$="-selectLabel"] {
    display: none;
  }

  fieldset[class$="-MuiOutlinedInput-notchedOutline"] {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }

  div[class$="-MuiInputAdornment-root"] {
    margin-right: 0px;
  }

  div[class$="-MuiInputBase-root-MuiOutlinedInput-root"]{
    padding: 0px;
  }

  input[class$="-MuiInputBase-input-MuiOutlinedInput-input"]{
    padding: 3px;
  }
  
  td[class$="MuiTableCell-root"]{
    padding: 4px !important;
  }
}

.payrollBox{
  padding-right: 1.5rem;
}

.UWdashboard_title {
  text-align: center;
  margin: 0px auto;
  font-family: Montserrat;
}

.statusDrop {
  background: transparent;
  color: black ;
  border: 1px solid black;
  border-radius: 4px;
  padding: "0px 15px 0px 10px";
  width: 100% ;
  outline: "none";
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -4px;
  box-shadow: 0.5px 2px 2px gray;
  height: 1.8rem;
}

@media only screen and (min-width: 1300px) {
  .submission_uw_dashboard {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 1410px) {
  .submission_uw_dashboard {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 1400px) {
  .UWdashboard_title {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1500px) {
  .submission_uw_dashboard {
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media only screen and (min-width: 1600px) {
  .submission_uw_dashboard {
    padding-left: 65px;
    padding-right: 65px;
  }
}
@media only screen and (min-width: 1700px) {
  .submission_uw_dashboard {
    padding-left: 75px;
    padding-right: 75px;
  }
}
@media only screen and (min-width: 1800px) {
  .submission_uw_dashboard {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media only screen and (min-width: 1900px) {
  .submission_uw_dashboard {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media only screen and (min-width: 2000px) {
  .submission_uw_dashboard {
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media only screen and (max-width: 900px) {
  .dashboard-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .lib_dashboard {
    padding-left: 5px;
    padding-right: 5px;
  }
  .UWdashboard_title {
    font-size: 1.5rem;
  }
}

// Scroll icons in dashboard
/*.ChevronRight {
  position: fixed;
  right: 10%;
  width: 2.5%;
  bottom: 32%;
  z-index: 10;
  border: none;
  opacity: 0.5;
  border-radius: 50%;
  color: #312f2f;
  font-weight: bold;
  font-size: 30px;
  transition: 0.1s;
}
.ChevronLeft {
  position: fixed;
  left: 10%;
  width: 2.5%;
  bottom: 32%;
  z-index: 10;
  border: none;
  opacity: 0.5;
  border-radius: 50%;
  color: #312f2f;
  font-weight: bold;
  font-size: 30px;
  transition: 0.1s;
}

.ChevronLeft:hover {
  color: black !important;
  font-size: 32px;
  opacity: 0.9;
  z-index: 10;
}
.ChevronRight:hover {
  color: rgb(37, 21, 21) !important;
  font-size: 32px;
  opacity: 0.9;
  z-index: 10;
}

@media only screen and (max-width: 1600px) {
  .ChevronRight {
    // top: 78%;

    font-size: 30px;
    transition: 0.1s;
    width: 3%;
  }
  .ChevronLeft {
    // top: 78%;

    font-size: 30px;
    transition: 0.1s;
    width: 3%;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 32px;
  }
  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 32px;
  }
}
@media only screen and (max-width: 1400px) {
  .ChevronRight {
    font-size: 28px;
    width: 4%;
    display: none;
  }
  .ChevronLeft {
    font-size: 28px;
    width: 4%;
    display: none;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 30px;
  }
  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 30px;
  }
}
@media only screen and (max-width: 750px) {
  .ChevronRight {
    font-size: 25px;
    width: 5%;
    display: none;
  }
  .ChevronLeft {
    font-size: 25px;
    width: 5%;
    display: none;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 27px;
  }
  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 27px;
  }
}
 */
