.historicalClaimsContainer {
  padding: 1rem;

  .claimTable {
    .form-group {
      margin-bottom: 2px;
      label,
      input {
        font-size: 16px !important;
        color: black;
      }
      input {
        box-shadow: 0.5px 1px 4px grey;
      }
    }
    input[type="text"] {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #fff;
      background: #fff;
    }
    .form-group {
      select,
      input {
        height: 35px;
        color: #2b2d31;
        font-size: 14px;
        padding-left: 5px;
      }
      label {
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 11px;
        color: #333;
        margin-bottom: 0;
        &.mandatory::after {
          content: "*";
          padding-left: 2px;
        }
      }
      p.errMsg {
        color: red;
        font-size: 11px;
      }
    }
    input {
      height: 36px;
      margin-bottom: "none";
      color: #2b2d31;
      font-size: 14px;
    }
    color: #919399;
    font-weight: "normal";
    border-collapse: separate;
    border-spacing: 20px 0px;
    .row-no-coverage {
      .no-coverage {
        width: 30%;
        margin-bottom: 20px;
        input {
          height: 12px;
          margin-bottom: 25px;
          margin-right: 5px;
        }
      }
    }
  }
}