.cmprQts {
  //style of the background image:
  background-image: url("../../../images/backimage4.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-attachment: sticky;
}

@mixin cmprQts {
  //style of the background image:
  background-image: url("../../../images/backimage4.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-attachment: sticky;
  .quote-table {
    .quote-table-company-name {
      padding-right: calc(2rem + 25px);
    }
    .head-row {
      align-items: center;
      justify-content: space-between;
    }
    .hr1 {
      color: #2c2e33;
      font-size: 26px;
      font-weight: 500;
      &.quote-table-company-name {
        // font-family: "Overpass", sans-serif;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .hr2 {
      color: #2c2e33;
      width: 70%;
      margin-top: 1rem;
      margin-bottom: 1rem;
      span {
        color: #5f636b;
      }
    }
    .hr3 {
      margin-bottom: 1.5rem;
      color: #2c2e33;
    }
    .body-row {
      padding-left: 6px;
      margin-top: 2rem;
      // justify-content: left;
      .table {
        margin-bottom: 7rem;
        position: relative;
        right: 2rem;
        border-collapse: separate;
        border-spacing: 25px 0px;
        thead {
          th {
            color: #2c2e33;
            position: relative;
            right: 0.6rem;
            font-size: 16px;
          }
        }
        tbody {
          padding-left: 5px;
          padding-right: 5px;
          font-size: 18px;
          td,
          th {
            width: 25%;
            border: 1px solid white;
            background-color: white;
            color: #2c2e33;
            font-size: 18px;
            font-weight: normal;
            div {
              height: 100%;
              label {
                margin-bottom: 0;
              }
            }
          }
          td {
            label {
              font-size: 22px;
              font-weight: 800;
              color: #1463ac;
              margin-bottom: 0;
            }
            .best {
              font-size: 14px;
              margin-left: 1rem;
              color: #038b00;
            }
            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }
            .uw {
              font-size: 14px;
              margin-left: 1rem;
            }
            input {
              margin: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.cmprQts {
  @include cmprQts;
  .quote-table {
    button {
      color: white;
      font-family: "MontserratSemiBold";
      border-radius: 12px;
    }
    .peo-btn {
      font-size: 1rem;
      width: 6rem;
      height: 2.5rem;
      box-shadow: 0px 5px 12px grey;
      background-color: #8ab93f;
      margin-right: 2.6rem;
    }
    .amtrust-btn,
    .key_risk-btn {
      font-size: 1rem;
      width: 6rem;
      height: 2.5rem;
      box-shadow: 0px 5px 12px grey;
      background-color: gray;
      margin-left: 2.5rem;
    }
    .quote-button-container {
      .close-btn,
      .export-btn {
        box-shadow: 0px 5px 12px grey;
        background-color: rgb(254, 174, 1);
      }
      .close-btn {
        width: 7rem;
      }
    }
  }
  .quote-table-box {
    box-shadow: 0.5px 1px 5px 3px grey;
  }
  .quote-table-box {
    background-color: white;
    padding: 1rem;
    .quote-button-container {
      display: flex;
      justify-content: center;
      // padding-inline: 300px;
    }
    .table-wrapper {
      max-height: 400px;
      border-top: 4px solid black;
    }
    table {
      border: 1px solid black;
    }
    th {
      background-color: #3e9ccc;
      color: white;
      text-align: center;
      vertical-align: middle;
      position: sticky;
      top: 0;
    }
    td {
      white-space: nowrap;
      // width: 50%;
      text-align: center;
      vertical-align: middle;
      max-width: 80px;
    }
    tr:nth-child(even) {
      background-color: rgba(128, 128, 128, 0.535);
    }
    tr th:first-child {
      border-left: 4px solid black;
    }
    tr th:last-child {
      border-right: 4px solid black;
    }
    tr td:first-child {
      border-left: 4px solid black;
    }
    tr td:last-child {
      border-right: 4px solid black;
    }
    tbody:last-child {
      border-bottom: 4px solid black;
    }
    tbody:first-child {
      margin-top: 0px;
    }
    tbody:before {
      content: "@";
      display: block;
      line-height: 0px;
      text-indent: -999999px;
    }
  }
  .emod-ques,
  .historical-claims {
    margin-top: 3rem;
  }
  .emod-ques {
    input[type="radio"] {
      width: 20px;
      height: 20px;
      display: grid;
      place-content: center;
      background-color: transparent;
      cursor: pointer;
    }
    input[type="radio"]:after {
      content: "";
      width: 0.65em;
      height: 0.65em;
      border-radius: 40%;
      transform: scale(0);
      transition: 2s transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
    }
    input[type="radio"]:checked:after {
      transform: scale(1);
      border: 1px solid #0763ee83;
      box-shadow: #0763ee83;
      transition: 2s transform ease-in-out;
    }
    .form-group {
      margin-bottom: 2px;
      label,
      input {
        font-size: 16px !important;
        color: black;
      }
      input {
        box-shadow: 0.5px 1px 4px grey;
      }
    }
    input[type="text"] {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #fff;
      background: #fff;
      box-shadow: 0.5px 1px 4px grey;
    }
    #dateRate1 {
      background: none;
      border: none;
    }
  }
  .grey-table-row {
    position: relative;
    right: 7px;
  }
  .historical-claims-container {
    padding: 1rem;
  }
}

.cmprQts {
  .quotes-table {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(1) {
    padding: 0px 0px 2.5px 0px !important;
  }
  .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
    // width: 200px !important;
    box-shadow: none !important;
  }

  .MuiPaper-root {
    font-family: "MontserratSemiBold";
  }
  .MuiToolbar-regular {
    min-height: 0px !important;
  }
  .MuiPaper-elevation2 {
    box-shadow: none !important;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      background-color: #e2f2ff !important;
      color: #606558;
    }
  }

  .MuiTableCell-root {
    padding: 7px 0px 7px 0px !important;
    border: none;
  }
  .chekcbox-input {
    height: 15px !important;
    width: 15px !important;
    align-items: center;
    // margin-left: 5px;
  }
  .fontName-Size,
  .viewQuoteButton {
    font-size: 1.3rem;
  }
 
  .MuiTable-root {
    background-color: #e2f2fe;
  }
  .MuiTable-root .MuiTableHead-root {
    box-shadow: none !important;
    border-style: none !important;
  }
  .Saic-column {
    color: red !important;
  }
}

.compareQuote {
  text-align: left;

  .constant-margin-top-bottom {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .text-align-left {
    text-align: left;
  }

  .green-text {
    color: #28a745;
  }

  .blue-text {
    color: #51a0d8;
  }

  .brown-text {
    color: #824704;
  }

  .red-text {
    color: #ab111b;
  }

  .dark-blue-text {
    color: blue;
  }
  .dark-blue {
    color: darkblue;
  }
  td,
  th {
    width: auto;
  }

  .switch_container {
    .custom-control-label::before {
      color: #fff;
      background-color: #e97d88;
      // border-color: #e97d88;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      // border-color: #28a745;
      background-color: #28a745;
    }
    .custom-switch .custom-control-label::after {
      background-color: #fff;
    }
  }

  .debit_credit_num {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // /* Firefox */
    // input[type="number"] {
    //   -moz-appearance: textfield;
    // }
  }

  .debit_credit_num_container {
    padding-inline: 10px;
  }
}

.compareQuote {
  .quote-action-buttons {
    gap: 2rem;
  }
  .btnSubmits {
    margin-top: 2rem;
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #ffd13f;
    border-color: #eea236;
    box-shadow: none;
    color: white;
    // font-size: 14px;
    font-weight: 800;
  }
  .btnView {
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #1463ac;
    margin-right: 1rem;
    margin-top: 2rem;
    // font-size: 14px;
    font-weight: 800;
  }
}

.submit_to_underwriter {
  // text-align: left;
 
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-inline: 5rem;
}

// media queries

@media only screen and (max-width: 750px) {
  .cmprQts {
    padding-left: 0px !important;
    padding-right: 0px !important;

    .MuiTableCell-head {
      width: 190px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 190px !important;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .cmprQts {
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
      max-width: 280px !important;
    }
    .MuiTableCell-head {
      width: 190px !important;
      max-width: 280px !important;
    }
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
      max-width: 280px !important;
    }
    .MuiPaper-root > div:nth-child(2) ::-webkit-scrollbar {
      height: 18px !important;
      // background-color: #e2f2ff;
    }
  }
  .quote_table_4header {
    width: 94.2% !important;
  }
  .quote_table_3header {
    width: 85% !important;
  }
  .quote_table_2header {
    width: 70% !important;
  }
  .quote_table_1header {
    width: 50% !important;
  }
}

@media only screen and (max-width: 1600px) {
  .cmprQts {
    .MuiTableCell-head {
      width: 220px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }

    .MuiPaper-root > div:nth-child(2) ::-webkit-scrollbar {
      height: 18px !important;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 82% !important;
  }
  .quote_table_1header {
    width: 62% !important;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 1850px) {
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 90% !important;
  }
  .quote_table_2header {
    width: 62% !important;
  }
  .quote_table_1header {
    width: 42% !important;
  }
  .cmprQts {
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1.5rem !important;
      padding: 15px 0px 15px 0px !important;
    }
    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.3rem;
    }
    .quotes-table {
      padding: 1rem !important;
    }
    .quote_table_4header {
      width: 100% !important;
    }
    .quote_table_3header {
      width: 100% !important;
    }
    .quote_table_2header {
      width: 82% !important;
    }
    .quote_table_1header {
      width: 62% !important;
    }
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 82% !important;
  }
  .quote_table_1header {
    width: 62% !important;
  }
  .cmprQts {
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.3rem;
    }
    .quotes-table {
      padding: 1rem !important;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 82% !important;
  }
  .quote_table_1header {
    width: 62% !important;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .cmprQts{
    .MuiTableCell-head {
      width: 320px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 320px !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.3rem;
    }
    .quotes-table {
      padding: 1rem !important;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 82% !important;
  }
  .quote_table_1header {
    width: 62% !important;
  }
  .cmprQts {
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
  }
}
@media only screen and (min-width: 1080px) and (max-width: 1280px) {
  .cmprQts{
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 180px !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.3rem;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 87% !important;
  }
  .quote_table_1header {
    width: 79% !important;
  }
  .cmprQts {
    .quotes-table {
      padding-left: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .cmprQts{
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 13px 0px 14px 0px !important;
    }
    .fontName-Size,
    .viewQuoteButton {
      font-size: 1.2rem;
    }
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 180px !important;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 95% !important;
  }
  .quote_table_1header {
    width: 85% !important;
  }
  .cmprQts {
    .quotes-table {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
  }
}
@media only screen and (min-width: 901px) and (max-width: 1079px) {
  .cmprQts{
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 13px 0px 13px 0px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 180px !important;
    }
    .quotes-table {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.2rem;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 95% !important;
  }
  .quote_table_1header {
    width: 90% !important;
  }
  .cmprQts {
    .quotes-table {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  .cmprQts{
    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 0.9rem !important;
      }
    }
    .quotes-table {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.2rem;
    }
    .fontName-Size {
      font-size: 1.3rem !important;
    }
    .fontName-Size1 {
      font-size: 1.5rem !important;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 95% !important;
  }
  .quote_table_1header {
    width: 95% !important;
  }
  .cmprQts {
    .quotes-table {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .cmprQts{
    .fontName-Size {
      font-size: 1.2rem !important;
    }
    .fontName-Size1 {
      font-size: 1.4rem !important;
    }
    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 0.9rem !important;
      }
    }
    .viewQuoteButton {
      font-size: 1.2rem;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 95% !important;
  }
  .quote_table_1header {
    width: 95% !important;
  }
}
@media only screen and (max-width: 680px) {
  .cmprQts{
    font-size: smaller !important;
    .MuiTableCell-head {
      width: 220px !important;
      padding: 10px 0px 10px 0px !important;
    }
    .quotes-table {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 220px !important;
    }
    .fontName-Size {
      font-size: 1.1rem !important;
    }
    .fontName-Size1 {
      font-size: 1.2rem !important;
    }
    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 0.9rem !important;
      }
    }
    .viewQuoteButton {
      font-size: 1.2rem;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 100% !important;
  }
  .quote_table_1header {
    width: 100% !important;
  }
}
@media only screen and (max-width: 580px) {
  .cmprQts {
    .fontName-Size {
      font-size: 0.9rem !important;
    }
    .fontName-Size1 {
      font-size: 1rem !important;
    }
    .MuiTableCell-head {
      width: 200px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 200px !important;
    }
    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 0.9rem !important;
      }
    }
    .quotes-table {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .viewQuoteButton {
      font-size: 1.2rem;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 100% !important;
  }
  .quote_table_1header {
    width: 100% !important;
  }
}
@media only screen and (max-width: 490px) {
  .cmprQts{
    .quotes-table {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .fontName-Size {
      font-size: 0.8rem !important;
    }
    .fontName-Size1 {
      font-size: 0.9rem !important;
    }
    .MuiTableCell-head {
      width: 185px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 185px !important;
    }
    .chekcbox-input {
      height: 15px !important;
      width: 15px !important;
    }
    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 0.9rem !important;
      }
    }
    .viewQuoteButton {
      font-size: 1.2rem;
    }
  }
  .quote_table_4header {
    width: 100% !important;
  }
  .quote_table_3header {
    width: 100% !important;
  }
  .quote_table_2header {
    width: 100% !important;
  }
  .quote_table_1header {
    width: 100% !important;
  }
}

@media only screen and (max-width: 400px) {
  .cmprQts{
    .fontName-Size {
      font-size: 0.7rem !important;
    }
    .fontName-Size1 {
      font-size: 0.7rem !important;
    }
    .MuiTableCell-head {
      width: 95px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 90px !important;
    }
    .chekcbox-input {
      height: 13px !important;
      width: 13px !important;
    }
    .spacing__row {
      display: none;
    }
  }
}