.tableQues {
  width: 60%;
}

.tableRemarks {
  padding-right: 5px;
  input {
    height: 32px;
    border: 1px solid #aaa;
  }
  .hidden {
    background-color: #cfe3f2;
    // background-color: rgba(131, 165, 191, 0.2);
    border: none;
  }
}

.font_family_montserrat_semi_bold {
  font-family: "MontserratSemiBold";
}

.emodDate,
form select,
form input[type="text"],
form input[type="email"],
form input[type="password"],
form input[type="date"],
form textarea {
  width: 100%;
  // font-size: 0.85em;
  border-radius: 4px;
  border: 1px solid white;
  background: white;
  color: black; //#2b2d31;
  font-weight: "normal";
}

@media screen and (max-width: 992px) {
  .row {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 576px) and (max-width: 993px) {
  .emod_section_ques {
    flex-direction: row;
  }
  .emod_ques_date {
    width: 13rem;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

@media all and (min-width: 993px) and (max-width: 1170px) {
  .emod_ques_date {
    flex-wrap: nowrap;
  }
}
