.font-family-montserrat-semi-bold {
  font-family: "MontserratSemiBold";
}

.calcPremium {
  color: #333;
  input {
    height: 36px;
    color: #2b2d31;
    padding-right: 5px;
  }
}

.Workerscomp_margin {
  margin-top: -20px;
}

.descriptionCode {
  width: 8%;
  padding-inline: 15px;
}

.form_group {
  margin-bottom: 38px;
  select,
  input {
    height: 35px;
    color: black;
    // font-size: 14px;
    padding-left: 5px;
    // box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
    box-shadow: 0.5px 1px 4px grey;
    border: white;
    width: 100%;
  }
  label {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // font-size: 11px;
    color: #333;
    margin-bottom: 0;
    &.mandatory::after {
      content: "*";
      padding-left: 2px;
    }
  }
  p.errMsg {
    color: red;
    font-size: 11px;
  }
}

.code_delete {
  width: 4%;
  padding-inline: 2px;
}

.transpButton {
  background-color: transparent;
  border-color: transparent;
}

@media all and (min-width: 993px) and (max-width: 1370px) {
  .Workerscomp_margin {
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 993px) {
  .code_delete {
    margin: auto;
  }
  .delete_records_btn {
    margin-top: -2.5rem;
    margin-bottom: 3rem;
  }
}
