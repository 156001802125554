.formTitle {
    color: #2c2e33;
    // font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.38;
    &.location-details-heading {
      font-weight: 400;
      font-size: 1.2rem;
    }
    &.compName {
      // font-family: Overpass;
      font-size: 20px;
      font-weight: 600;
      color: #2c2e33;
      // padding-left: 4rem;
    }
    &.title2 {
      width: 70%;
      display: inline-block;
    }
    &.title3 {
      font-size: 20px;
      font-weight: normal;
    }
  }

  .undrQues {
    // style for the background image:
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 95%;
    background-attachment: sticky;
    //style for the unerwriter questions and additional questions table:
    .heading_row {
      justify-content: space-between;
      .compName {
        margin-top: 2px;
        color: #2c2e33;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .errMsg {
    color: red;
  }

  .btnSubmits {
    // margin-top: 2rem;
    border-radius: 7.2px;
    background-color: #ffd13f;
    border-color: #eea236;
    box-shadow: 2px 4px 12px #eea236;
    color: white;
    font-size: 1rem;
    font-weight: 800;
  }

  @media only screen and (max-width: 700px) {
    .finallyquestionwidth {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }