.font_family_montserrat_semi_bold {
  font-family: "MontserratSemiBold";
}

.form_group {
  margin-bottom: 38px;
  select,
  input {
    height: 35px;
    color: black;
    // font-size: 14px;
    padding-left: 5px;
    // box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
    box-shadow: 0.5px 1px 4px grey;
    border: white;
    width: 100%;
  }
  label {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // font-size: 11px;
    color: #333;
    margin-bottom: 0;
    &.mandatory::after {
      content: "*";
      padding-left: 2px;
    }
  }
  p.errMsg {
    color: red;
    font-size: 11px;
  }
}

class-desc-select .class_desc_select__UyJxT__menu {
  height: fit-content;
  width: fit-content;
  max-width: 500px;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
}

.class_desc_select {
  background-color: white;
}

.worker_comp_code_input {
  input {
    box-shadow: none;
  }
}

.class_desc_select {
  div {
    height: 35px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.class_desc_select__UyJxT__indicator {
  align-items: center;
}

.class_desc_select__UyJxT__menu {
  height: fit-content;
  width: fit-content;
  max-width: 350px;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
}

.class_desc_select__UyJxT__menu-list {
  height: fit-content;
  max-height: 150px;
}

.class_desc_select__UyJxT__option {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: fit-content;
}

.class_desc_select__UyJxT__placeholder {
  margin: 0;
  position: unset;
  transform: none;
  height: fit-content;
}

.class_desc_select__UyJxT__value-container {
  overflow: initial;
}

.class_desc_select__UyJxT__single-value {
  height: fit-content;
}

.descriptioncode_errmsg {
  color: red;
  font-size: 11px;
}

@media only screen and (max-width: 993px) {
  .worker_comp_code_input {
    width: 100%;
    padding-left: 15px;
  }
}
