.Landing__page__image {
  object-fit: contain;
  height: 550px;
}

.workcomp_line {
  background-color: #497fbc;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 40px;
  p {
    font-size: 60px;
    text-align: center;
    margin-top: 20px;
    font-family: "MontserratSemiBold";
    line-height: 1.5;
    font-weight: 700;
  }
}

.tab_content {
  // height: 600px;
  min-height: calc(100vh - 9rem);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.start_new_quote{
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.Startnewquotefont{
  text-align: center;
}

.start_new_quote_link{
  color: #497fbc;
}

.formChange {
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

.formLogin {
  height: auto;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

.formReset {
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

.formChange,
.formLogin,
.formReset {
  border: 1px solid #f9f9f9;
}

.formChange:hover,
.formLogin:hover,
.formReset:hover {
  box-shadow: 0 10px 15px #888888;
  transition: all 0.5s;
}

.formChange,
.formLogin,
.formReset {
  margin-bottom: 16px;
  .formTitle {
    font-size: 20px;
  }
  background-color: #e2f2ff;
  form {
    padding: 0 20px;
  }
  form input[type="password"],
  form input[type="email"],
  form input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  button {
    height: 35px;
    width: 100%;
    border-radius: 5px;
    background-image: linear-gradient(to right, #10c03e, #0eaf84);
    box-shadow: none;
    border-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .formBottomLinks {
    justify-content: space-between;
    a {
      color: #2c2e33;
    }
  }
  .inptSideText {
    color: #5f636b;
    margin-left: 3px;
  }
}

.errMsg{
  color:red;
}

.formBottomLinks {
  padding: 20px;
}

.submitDiv{
    display: flex;
    background-color: #095d98;
    width: 100% ;
    border: none;
    box-shadow: 3px 3px 5px #555555;
    border-radius: 4px;
    justify-content: center !important;
}

.resetSubmitDiv{
  display: flex;
  border: none;
  justify-content: center !important;
}
// Media Queries

@media only screen and (max-width: 374px) {
  .Landing__page__image {
    height: 250px;
  }
}

@media all and (min-width: 375px) and (max-width: 451px) {
  .Landing__page__image {
    object-fit: contain;
    height: 300px;
  }
  .formLogin {
    height: auto;
    width: 370px;
    position: relative;
    padding-top: 20px;
    transition: all 0.5s;
  }
}

@media all and (min-width: 451px) and (max-width: 600px) {
  .Landing__page__image {
    object-fit: contain;
    height: 350px;
  }
}

@media all and (min-width: 601px) and (max-width: 767px) {
  .Landing__page__image {
    object-fit: contain;
    height: 400px;
  }
}

@media all and (min-width: 768px) and (max-width: 900px) {
  .Landing__page__image {
    object-fit: contain;
    height: 600px;
  }
}

@media all and (min-width: 901px) and (max-width: 1224px) {
  .Landing__page__image {
    object-fit: contain;
    height: 600px;
  }
}

@media all and (min-width: 1225px) and (max-width: 1440px) {
  .Landing__page__image {
    object-fit: contain;
    height: 550px;
  }
}

@media all and (min-width: 1441px) and (max-width: 1500px) {
  .Landing__page__image {
    object-fit: contain;
    height: 490px;
  }
}
@media all and (min-width: 1501px) and (max-width: 1600px) {
  .Landing__page__image {
    object-fit: contain;
    height: 510px;
  }
}
@media all and (min-width: 1601px) and (min-width: 1700px) {
  .Landing__page__image {
    object-fit: contain;
    height: 530px;
  }
}

@media only screen and (max-width: 1010px) {
  .workcomp_line_paragraph  {
    text-align: center !important;
    font-size: 40px !important;
  }
}

@media only screen and (max-width: 700px) {
  .workcomp_line_paragraph  {
    text-align: center !important;
    font-size: 30px !important;
  }
  .Startnewquotefont {
    font-size: 1.5rem !important;
  }
}

@media only screen and (max-width: 500px) {
  .workcomp_line_paragraph  {
    text-align: center !important;
    font-size: 20px !important;
  }
  .formLogin {
    height: auto;
    width: 400px;
    position: relative;
    padding-top: 20px;
    transition: all 0.5s;
  }
}

@media only screen and (max-width: 400px) {
  .formLogin {
    height: auto;
    width: 330px;
    position: relative;
    padding-top: 20px;
    transition: all 0.5s;
  }
}

@media only screen and (max-width: 372px) {
  .formLogin {
    height: auto;
    width: 280px;
    position: relative;
    padding-top: 20px;
    transition: all 0.5s;
  }
}