.dropdown_element_container{
  height: 35px;
  color: black;
  box-shadow: 0.5px 1px 4px grey;
  div[class$="-ValueContainer"]{
    padding-left: 3px;
    color:grey;
  }
  
  div[class$="-Input"]{
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  div[class$="-singleValue"]{
    color:#5d667b;
  }
}
