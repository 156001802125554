.location_payroll_container {
  margin-bottom: -15px;
}
// .location_payroll_container {
//   #perCKdollar {
//     width: 6rem;
//   }
//   #adminPercent {
//     width: 5rem;
//   }
//   #requested-pricing-radio-yes,
//   #requested-pricing-radio-no {
//     margin-inline: 10px;
//     align-self: center;
//   }
//   input[type="radio"] {
//     width: 18px;
//     height: 18px;
//     display: grid;
//     place-content: center;
//     background-color: transparent;
//     cursor: pointer;
//   }
//   input[type="radio"]::after {
//     content: "";
//     width: 0.65em;
//     height: 0.65em;
//     border-radius: 40%;
//     transform: scale(0);
//     transition: 2s transform ease-in-out;
//     box-shadow: inset 1em 1em var(--form-control-color);
//   }
//   input[type="radio"]:checked::after {
//     transform: scale(1);
//     border: 1px solid #0763ee83;
//     box-shadow: #0763ee83;
//     transition: 2s transform ease-in-out;
//   }
// }

.allLocations {
  margin-top: 6px;
}

.addLoc {
  margin-bottom: 18px;
}

.transpButton {
  background-color: transparent;
  border-color: transparent;
}

.hr {
  margin-bottom: 2rem;
}
