.pink_header {
  color: #497fbc;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  padding-bottom: 35px;
}

.font_family_montserrat_bold {
  font-family: "MontserratBold";
}

.pink_sign {
  font-weight: 800;
  margin-left: 15px;
  color: rgb(255, 255, 255);
  background-color: #2c2e33;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
