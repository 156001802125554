.emod_ques {
  input[type="radio"] {
    width: 20px;
    height: 20px;
    display: grid;
    place-content: center;
    background-color: transparent;
    cursor: pointer;
  }
  input[type="radio"]:after {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 40%;
    transform: scale(0);
    transition: 2s transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }
  input[type="radio"]:checked:after {
    transform: scale(1);
    border: 1px solid #0763ee83;
    box-shadow: #0763ee83;
    transition: 2s transform ease-in-out;
  }
  .form-group {
    margin-bottom: 2px;
    label,
    input {
      font-size: 16px !important;
      color: black;
    }
    input {
      box-shadow: 0.5px 1px 4px grey;
    }
  }
  input[type="text"] {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0.5px 1px 4px grey;
  }
  #dateRate1 {
    background: none;
    border: none;
  }
}

.emod_ques {
  margin-top: 44px;
}

.emod_ques_heading {
  margin-bottom: 30px;
}
