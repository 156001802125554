.loc1street_margin {
  margin-top: -10px;
  .form_group {
    margin-bottom: 38px;
    select,
    input {
      height: 35px;
      color: black;
      // font-size: 14px;
      padding-left: 5px;
      // box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
      box-shadow: 0.5px 1px 4px grey;
      border: white;
      width: 100%;
    }
    label {
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      // font-size: 11px;
      color: #333;
      margin-bottom: 0;
      &.mandatory::after {
        content: "*";
        padding-left: 2px;
      }
    }
    p.errMsg {
      color: red;
      font-size: 11px;
    }
  }
}

.locHeading {
  width: 8%;
}

.errMsg {
  color: red;
}

.address_delete {
  width: 4%;
  display: flex;
  align-items: center;
  margin-top: -1.2rem;
  padding-inline: 2px;
}

.transpButton {
  background-color: transparent;
  border-color: transparent;
}

.add_classcode_margin {
  margin-top: -15px;
}

.add_classcode_leftmargin {
  width: 6%;
  padding-inline: 15px;
}

// .add_classcode_container {
//   width: 13%;
// }

.addLoc {
  margin-bottom: 18px;
}

@media only screen and (max-width: 993px) {
  .address_delete {
    margin: auto;
  }
  .address_delete_btn {
    margin-bottom: 3rem;
    margin-top: -1rem;
  }
}
