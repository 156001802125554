.company_name {
  cursor: pointer;
  color: cornflowerblue;
}
.company_name:hover {
  text-decoration: underline;
  color: rgba(100, 148, 237, 0.575);
}

.dashboardMaterialTable {
  position: relative;

  p[class$="-displayedRows"],
  p[class$="-selectLabel"] {
    display: none;
  }

  fieldset[class$="-MuiOutlinedInput-notchedOutline"] {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }

  div[class$="-MuiInputAdornment-root"] {
    margin-right: 0px;
  }

  div[class$="-MuiInputBase-root-MuiOutlinedInput-root"]{
    padding: 0px;
  }

  input[class$="-MuiInputBase-input-MuiOutlinedInput-input"]{
    padding: 3px;
  }

  td[class$="MuiTableCell-root"]{
    padding: 4px !important;
  }
}

.dashboardHeader {
  border-bottom-style: groove;
}

.lib_dashboard {
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: 1px 1px 12px 0px;
}

.underwriter_comment {
  color: #000;
  padding-inline: 15px;
  box-shadow: 0.5px 1px 4px grey;
  border-color: white;
}

@media only screen and (min-width: 1310px) {
  .lib_dashboard {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}
@media only screen and (min-width: 1410px) {
  .lib_dashboard {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
}
@media only screen and (min-width: 1500px) {
  .lib_dashboard {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
}
@media only screen and (min-width: 1600px) {
  .lib_dashboard {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }
}
@media only screen and (min-width: 1800px) {
  .lib_dashboard {
    padding-left: 210px !important;
    padding-right: 210px !important;
  }
}
@media only screen and (min-width: 1900px) {
  .lib_dashboard {
    padding-left: 250px !important;
    padding-right: 250px !important;
  }
}

@media only screen and (min-width: 2000px) {
  .submission-uw-dashboard {
    padding-left: 270px !important;
    padding-right: 270px !important;
  }
}
