.navcontent {
  gap: 4rem;
  grid-gap: 4rem;
}

a{
  text-decoration: none;
}

//media queries

@media only screen and (max-width: 900px) {
  .navcontent {
    display: flex !important;
    flex-direction: column !important;
    gap: 2rem;
    font-weight: 900;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 1166px) {
  .navcontent {
    font-size: 14px !important;
    gap: 3rem;
  }
}

@media only screen and (max-width: 1053px) {
  .navcontent {
    font-size: 13px !important;
    gap: 2.8rem;
  }
}

@media only screen and (max-width: 1000px) {
  .navcontent {
    font-size: 12px !important;
    gap: 2.8rem;
  }
}