#riskprofile_table {
  .dropdown__container {
    @media only screen and (max-width: 700px) {
      display: flex;
      flex-direction: column !important;
    }
    .dropdown {
      min-width: 200px;
    }
  }
  table {
    td {
      border: 1.3px solid white;
      text-align: right;
      font-family: "MontserratSemiBold";
      padding: 0.2rem !important;
    }
    th {
      font-family: "MontserratBold";
      text-align: right;
      border: 1.3px solid white !important;
      font-size: 1.1rem !important;
    }
    td:nth-child(1) {
      background-color: #d5d5d5;
    }
    .key_header {
      text-align: left;
    }

    .table_rows {
      .key {
        border-left: 0.5px solid grey;
        border-right: 0.5px solid grey;
        text-align: left;
      }
      .premium,
      .premium_mix {
        border-right: 0.5px solid grey;
      }
    }
    .totals_row {
      background-color: #567fb7;
      color: white;
      td {
        font-family: "MontserratSemiBold";
      }
    }
    .table_header {
      background-color: #4c586b;
      color: white;
    }
    border-collapse: collapse;
    color: #292d2f;
    .Total_riskprofile {
      text-align: left;
      background-color: #577fb4 !important;
    }
  }
  .eligibility_table {
    .table_rows {
      td:nth-child(1) {
        text-transform: capitalize;
      }
    }
  }
  .naics_table,
  .sic_table,
  .eligibility_table {
    .table_name {
      background-color: #567eb7;
      text-align: center;
      vertical-align: middle;
      width: 12%;
    }
    .key,
    .key_header {
      width: 29%;
    }
    .payroll,
    .premium,
    .payroll_header,
    .premium_header {
      width: 13%;
    }
    .premium_mix,
    .mix_rate,
    .wage_mix,
    .premium_mix_header,
    .mix_rate_header,
    .wage_mix_header {
      width: 12%;
    }
  }
  .hazard_table,
  .state_table {
    td:nth-child(1) {
      text-align: center;
    }
    th:nth-child(2) {
      text-align: center;
    }
    .table_name {
      background-color: #567eb7;
      text-align: center;
      vertical-align: middle;
      width: 11%;
    }

    .key,
    .key_header {
      width: 19.5%;
    }
    .payroll,
    .premium,
    .payroll_header,
    .premium_header {
      width: 15.5%;
    }
    .premium_mix,
    .mix_rate,
    .wage_mix,
    .premium_mix_header,
    .mix_rate_header,
    .wage_mix_header {
      width: 14%;
    }
  }
  .state_table {
    margin-top: 30px;
  }
  .hazard_table,
  .state_table {
    width: 85%;
    float: right;
  }
}
@media only screen and (max-width: 1600px) {
  .hazard_table {
    .hazard_table,
    .state_table {
      width: 87%;
      float: right;
    }
    .table_name {
      width: 12% !important;
    }
    .key,
    .key_header {
      width: 17.5%;
    }
    .payroll,
    .premium,
    .payroll_header,
    .premium_header {
      width: 15.5%;
    }
    .premium_mix,
    .mix_rate,
    .wage_mix,
    .premium_mix_header,
    .mix_rate_header,
    .wage_mix_header {
      width: 14%;
    }
  }
}
@media only screen and (max-width: 1400px) {
  #riskprofile_table {
    .hazard_table,
    .state_table {
      width: 87%;
      float: right;
    }
    .hazard_table {
      .table_name {
        width: 15% !important;
      }
      .key,
      .key_header {
        width: 16.5%;
      }
      .payroll,
      .premium,
      .payroll_header,
      .premium_header {
        width: 15.5%;
      }
      .premium_mix,
      .mix_rate,
      .wage_mix,
      .premium_mix_header,
      .mix_rate_header,
      .wage_mix_header {
        width: 14%;
      }
    }

    .state_table {
      .table_name {
        width: 10% !important;
      }
      .key,
      .key_header {
        width: 18% !important;
      }
      .payroll,
      .premium,
      .payroll_header,
      .premium_header {
        width: 16% !important;
      }
      .premium_mix,
      .mix_rate,
      .wage_mix,
      .premium_mix_header,
      .mix_rate_header,
      .wage_mix_header {
        width: 15% !important;
      }
    }
  }
}
@media only screen and (max-width: 980px) {
  #riskprofile_table {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 786px) {
  #riskprofile_table {
    .state_table,
    .hazard_table {
      width: 100% !important;
      border-collapse: collapse !important;
      float: none !important;
    }
  }
}
@media only screen and (max-width: 750px) {
  .riskProfile_table_responsive {
    overflow-x: auto !important;
  }
  .Spacing_rating {
    display: flex;
    gap: 2rem;
  }
  #cmprQts,
  #underwriting_page {
    padding-left: 0px !important;
    padding-right: 0px !important;

    .MuiTableCell-head {
      width: 190px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 190px !important;
    }
  }
}

.pink_header {
  color: #497fbc;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  padding-bottom: 35px;
}
