.dropZonesContainer {
  padding: 0 25px;
  .customDropZone {
    .dropzone {
      text-align: center;
      padding: 20px;
      border: 2px dashed #363333;
      background-color: #fafafa;
      color: #8a5656;
      .dropZoneText {
        cursor: pointer;
      }
    }
    li {
      background: none;
      .fw-bold {
        cursor: pointer;
      }
    }
    .downloadImage{
      font-weight: 900; 
      font-size: 24px;
    }
  }
  .uploadButton{
    width: min-content; align-self: center;
  }
}