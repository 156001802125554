// .table_undrques {
//     tbody {
      
//     }
//   }

  .table_undrques {
    margin-bottom: 3.5rem;
    thead {
      color: #2c2e33;
      font-size: 1.2rem;
      tr {
        th {
          border: none;
        }
      }
    }
    tbody {
      font-size: 14px;
      .trow1 {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
      tr {
        td {
          vertical-align: middle;
          padding-top: 5px;
          padding-bottom: 5px;
          &.tableAns {
            .radio-btn-no {
              margin-left: 2.5rem;
              width: 18px;
              height: 18px;
            }
            .radio-btn-yes {
              margin-left: 1rem;
              width: 18px;
              height: 18px;
            }
            .radiobtn {
              margin-left: 4px;
              position: relative;
              bottom: 4px;
            }
          }
          &.tableQues {
            width: 60%;
          }
          &.tableRemarks {
            padding-right: 5px;
            input {
              height: 32px;
              border: 1px solid #aaa !important;
            }
            .hidden {
              background-color: #cfe3f2;
              // background-color: rgba(131, 165, 191, 0.2);
              border: none !important;
            }
          }
          &.table-question {
            margin: 10px;
          }
        }
        &:nth-child(odd) {
          background-color: #ffffff;
        }
      }
    }
    input{
      padding: 0.3rem !important;
    }
    .form-check-label {
      margin-bottom: 0 !important;
    }
  }





@media only screen and (max-width: 700px) {
  .bringcolumn {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 19px !important;
    padding-top: 10px 0px !important;
  }
  .responsivecolumn {
    display: contents !important;
  }
  .Startnewquotefont {
    font-size: 1.5rem !important;
  }
}